import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { postAudit } from 'utils/audit'
import { getCurrentJWToken, getEmailFromToken } from 'utils/utils'
import { useGuillotina } from './useGuillotina'

export function useFavourites() {
  const token = getCurrentJWToken()
  const email = getEmailFromToken()

  const { data, refresh, isLoading } = useGuillotina({ path: email, guillotinadb: true })

  const addFavourite = (uuid, action, isArray = false) => {
    fetchGuillotina({
      path: email,
      method: 'PATCH',
      data: {
        preferits: isArray
          ? [...(data?.preferits ?? []), ...uuid]
          : [...(data?.preferits ?? []), uuid],
      },
      token: token,
      guillotinadb: true,
    }).then(() => {
      postAudit({
        action,
        payload: { uuid },
      })
      refresh()
    })
  }

  const removeFavourite = (uuid, action, isArray = false) => {
    fetchGuillotina({
      path: email,
      method: 'PATCH',
      data: {
        preferits: (data?.preferits ?? []).filter((item) =>
          isArray ? !uuid.includes(item) : item !== uuid
        ),
      },
      token: token,
      guillotinadb: true,
    }).then(() => {
      postAudit({
        action,
        payload: { uuid },
      })
      refresh()
    })
  }

  return {
    favouiteIds: data?.preferits ?? [],
    loading: isLoading,
    isFavouritesLoading: isLoading,
    addFavourite,
    removeFavourite,
  }
}
