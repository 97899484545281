import { getCurrentEditionLocalStorage } from 'context/EditionContext'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { getCurrentJWToken, getEmailFromToken } from './utils'

export const AUDIT_ACTIONS = {
  PAGE_VIEW: 'pwa_page_view',
  CHANGE_EDITION: 'pwa_change_edition',
  CHANGE_LANGUAGE: 'pwa_change_language',
  USER_LOGIN: 'pwa_user_login',
  USER_LOGOUT: 'pwa_user_logout',
  USER_REGISTER: 'pwa_user_register',
  USER_FORGOT_PASSWORD: 'pwa_user_forgot_password',
  ADD_PROFESSIONAL_FAVOURITE: 'pwa_add_professional_favourite',
  REMOVE_PROFESSIONAL_FAVOURITE: 'pwa_remove_professional_favourite',
  ADD_PERFORMANCE_FAVOURITE: 'pwa_add_performance_favourite',
  REMOVE_PERFORMANCE_FAVOURITE: 'pwa_remove_performance_favourite',
  ADD_ACTIVITY_FAVOURITE: 'pwa_add_activity_favourite',
  REMOVE_ACTIVITY_FAVOURITE: 'pwa_remove_activity_favourite',
}

export const postAudit = async (data, token = getCurrentJWToken()) => {
  const auditValue = process.env.REACT_APP_ACTIVE_AUDIT
  const isActive = /^true$/i.test(auditValue)
  if (isActive) {
    if (token) {
      data['creator'] = getEmailFromToken(token)
    } else {
      data['creator'] = 'anonymous'
    }
    const edition = getCurrentEditionLocalStorage()
    data['payload'] = {
      ...(data['payload'] ?? {}),
      edition,
    }
    return fetchGuillotina({
      path: '@postAudit',
      method: 'POST',
      data,
      token,
    })
  }
}
