import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import WrapperUnauthAction from 'Components/WrapperUnauthAction'
import Button from 'NewVersion/components/UI/Buttons/Button'
import { TextInputField } from 'NewVersion/components/UI/Forms/TextInputField'
import Link from 'NewVersion/components/UI/Link/Link'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import 'NewVersion/styles/Login.scss'
import * as path from 'Routes/Paths'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AUDIT_ACTIONS, postAudit } from 'utils/audit'

function RecoveryPassword() {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [isLoading, setLoader] = useState(false)
  const [resetError, setResetError] = useState({
    haveError: false,
    message: '',
  })
  const [resetSend, setResetSend] = useState(false)

  const validateForm = () => {
    return false
  }

  const sendResetPassw = async (data) => {
    setResetError({
      haveError: false,
      message: '',
    })
    data.preventDefault()
    if (email !== '') {
      setLoader(true)
      await fetchGuillotina({
        path: `@users/${email}/reset-password`,
        method: 'POST',
        checkError: true,
        unAuth: true,
      })
        .then(function () {
          postAudit({
            action: AUDIT_ACTIONS.USER_FORGOT_PASSWORD,
            payload: { email },
          })
          setLoader(false)
          setResetSend(true)
        })
        .catch(function (error) {
          console.log('Error reset-password')
          if (
            error.response &&
            error.response.data &&
            error.response.data.text === 'Invalid operation'
          ) {
            setResetError({
              haveError: true,
              message: t('email_not_exists_reset_password'),
            })
          } else {
            setResetError({
              haveError: true,
              message: t('error_generic'),
            })
          }
          setLoader(false)
        })
    } else {
      setResetError({
        haveError: true,
        message: t('Aquest camp és obligatori'),
      })
    }
  }

  return (
    <WrapperUnauthAction>
      <section className="login">
        <div className="input-container">
          <h2 className="input-title">{t('Benvinguda')}</h2>
          <form className="inputs" onSubmit={sendResetPassw} data-test="resetPasswTest">
            <TextInputField
              type="text"
              label={t('correu')}
              onChange={({ target }) => setEmail(target.value)}
              error={!!resetError}
              dataTest="inputEmailTest"
              value={email}
            />
            {resetError && <p className="error">{resetError.message}</p>}
            {!isLoading ? (
              <Button
                disabled={validateForm()}
                color="primary"
                type="submit"
                dataTest="btnResetPasswTest"
              >
                {t('Recuperar')}
              </Button>
            ) : (
              <div style={{ height: '56px' }}>
                <LoadingComponent noClass={true} />
              </div>
            )}
            {resetSend && (
              <div className="forgotten-password">{t('Email de recuperació enviat')}</div>
            )}
            <div className="links">
              <Link href={path.LOGIN_PATH}>{t('Iniciar sessió')}</Link>
            </div>
          </form>
        </div>
        <div className="login-image" />
      </section>
    </WrapperUnauthAction>
  )
}

export default RecoveryPassword
