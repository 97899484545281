import { Box } from '@mui/system'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { AccreditationPersonDetailInfo } from 'NewVersion/components/Show/AccreditationPersonDetailInfo'
import { EmailIcon } from 'NewVersion/icons/EmailIcon'
import { FacebookIcon } from 'NewVersion/icons/FacebookIcon'
import { FavouriteIcon } from 'NewVersion/icons/FavouriteIcon'
import { InstagramIcon } from 'NewVersion/icons/InstagramIcon'
import { LinkIcon } from 'NewVersion/icons/LinkIcon'
import { TrashIcon } from 'NewVersion/icons/TrashIcon'
import { TwitterIcon } from 'NewVersion/icons/TwitterIcon'
import { capitalizeFirstLetter } from 'NewVersion/utils/text'
import { useFavourites } from 'hooks/useFavourites'
import { useGuillotina } from 'hooks/useGuillotina'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AUDIT_ACTIONS } from 'utils/audit'
import Chips from '../UI/Chips/Chips'

export function MainShow({ showData, artist }) {
  const { favouiteIds, addFavourite, removeFavourite, isFavouritesLoading } = useFavourites()
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [accreditationPerson, setAccreditationPerson] = useState(null)

  const { data: artistData, isLoading: artistLoading } = useGuillotina({
    path: showData ? artist : null,
  })

  const { data: artistAccreditations, isLoading: artistAccreditationsLoading } = useGuillotina({
    path: showData
      ? `${artist}/@search?type_name__in=AcreditacioProfessional,AcreditacioPremsa`
      : null,
  })

  const { data: manager, isLoading: managerLoading } = useGuillotina({
    path: artistData ? artistData.representant : null,
  })

  const { data: managerAccreditations, isLoading: managerAccreditationsLoading } = useGuillotina({
    path: manager
      ? `${artistData.representant}/@search?type_name__in=AcreditacioProfessional,AcreditacioPremsa`
      : null,
  })

  const transformTextMayus = (text) => {
    const result = []
    const arrayText = text.split(' ')
    arrayText.map((arrayTextEl) => {
      const textLower = arrayTextEl.toLowerCase()
      const textCapitalized = capitalizeFirstLetter(textLower)
      result.push(textCapitalized)
    })
    return result.join(' ')
  }

  if (
    artistLoading ||
    artistAccreditationsLoading ||
    managerLoading ||
    managerAccreditationsLoading
  )
    return <LoadingComponent />

  const renderScheduleButton = (entity) => {
    const isFavourite = favouiteIds && favouiteIds.includes(entity['@uid'])
    if (isFavourite) {
      return (
        <Chips
          text={t('delete')}
          icon={<TrashIcon />}
          dataTest={'deleteCalendar'}
          tooltip={t('remove_to_favourite')}
          disabled={isFavouritesLoading}
          onClick={(ev) => {
            ev.preventDefault()
            ev.stopPropagation()
            removeFavourite(entity['@uid'], AUDIT_ACTIONS.REMOVE_PROFESSIONAL_FAVOURITE)
          }}
        />
      )
    }
    return (
      <Chips
        text={t('afegir')}
        icon={<FavouriteIcon outline={true} />}
        dataTest={'addCalendar'}
        tooltip={t('add_to_favourite')}
        disabled={isFavouritesLoading}
        onClick={(ev) => {
          ev.preventDefault()
          ev.stopPropagation()
          addFavourite(entity['@uid'], AUDIT_ACTIONS.ADD_PROFESSIONAL_FAVOURITE)
        }}
      />
    )
  }

  const renderAccreditation = (accreditation, type) => {
    return (
      <div
        className="accreditation"
        data-test={`accreditationListTest${type}`}
        onClick={() => {
          setIsModalOpen(true)
          setAccreditationPerson(accreditation)
        }}
      >
        <b>{transformTextMayus(accreditation?.title ?? '--')}</b>
        {renderScheduleButton(accreditation)}
      </div>
    )
  }

  const renderLocation = ({ adreca, codi_postal, comarca, comunitat_autonoma, poblacio, pais }) => {
    const locationFirstLine = []
    const locationSecondLine = []

    if (adreca) {
      locationFirstLine.push(adreca)
    }
    if (codi_postal) {
      locationFirstLine.push(codi_postal)
    }
    if (poblacio) {
      locationFirstLine.push(poblacio)
    }
    if (comarca) {
      locationSecondLine.push(comarca)
    }
    if (comunitat_autonoma) {
      locationSecondLine.push(t(comunitat_autonoma))
    }
    if (pais) {
      locationSecondLine.push(t(pais))
    }

    if (locationFirstLine.length > 0 && locationSecondLine.length > 0) {
      return (
        <>
          {locationFirstLine.join(' ')}
          <br />
          {locationSecondLine.join(' ')}
        </>
      )
    } else if (locationSecondLine.length > 0) {
      return <>{locationSecondLine.join(' ')}</>
    } else if (locationFirstLine.length > 0) {
      return <>{locationFirstLine.join(' ')}</>
    }
    return null
  }

  const renderCardInfo = ({
    tipus,
    title,
    web,
    correu,
    telefon,
    facebook,
    instagram,
    twitter,
    ...props
  }) => {
    const location = renderLocation({ ...props })
    return (
      <div className="infoBoxWrapper" data-test={`infoBoxTest${props['@name']}`}>
        {tipus === 'representant' && <h4>{t('representant')}</h4>}
        <div className="infoBox">
          <div className="infoBoxHeader">
            <h4>{title}</h4>
            <div>
              {web && (
                <a href={web} target="_blank" rel="noreferrer">
                  <LinkIcon />
                </a>
              )}

              {correu && (
                <a href={`mailto:${correu}`}>
                  <EmailIcon />
                </a>
              )}
            </div>
          </div>

          <div className="infoBoxContent">
            {location && (
              <div>
                <b>{t('Adreca')}</b>
                <p>{location}</p>
              </div>
            )}

            {(telefon || correu) && (
              <div className="telAndEmail">
                {telefon && (
                  <div>
                    <p>
                      <b>{t('telefon')}</b>
                    </p>

                    <a href={`tel:${telefon}`}>{telefon}</a>
                  </div>
                )}

                {correu && (
                  <div>
                    <p>
                      <b>{t('Correu electrònic')}</b>
                    </p>
                    <a href={`mailto:${correu}`}>{correu}</a>
                  </div>
                )}
              </div>
            )}
            {web && (
              <div>
                <b>{t('web')}</b>
                <p>{web}</p>
              </div>
            )}
          </div>

          <div className="socialMedia">
            {facebook && (
              <a href={facebook}>
                <FacebookIcon color="#5C5958" />
              </a>
            )}
            {instagram && (
              <a href={instagram}>
                <InstagramIcon color="#5C5958" />
              </a>
            )}
            {twitter && (
              <a href={twitter}>
                <TwitterIcon />
              </a>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="mainShowBox">
      <AccreditationPersonDetailInfo
        person={accreditationPerson}
        setPersonNull={() => setAccreditationPerson(null)}
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />
      {manager ? renderCardInfo({ ...manager }) : renderCardInfo({ ...artistData })}
      <Box className="accreditedPersons" width={'100%'}>
        {(managerAccreditations?.items ?? []).length > 0 && (
          <div>
            <h4>{t('Acreditats del Representant')}</h4>
            {managerAccreditations?.items.map((managerAccreditation) => {
              return (
                <Fragment key={managerAccreditation['id']}>
                  <div className="separator" />
                  {renderAccreditation(managerAccreditation, 'manager')}
                  <div className="separator" />
                </Fragment>
              )
            })}
          </div>
        )}

        {((artistAccreditations?.items ?? []).length > 0 ||
          (managerAccreditations?.items ?? []).length > 0) && (
          <div>
            {manager ? (
              <Box mt={4}>
                <h5>{artistData.title}</h5>
              </Box>
            ) : (
              <h4>{t('Acreditats')}</h4>
            )}

            {artistAccreditations.items.map((artistAccreditation) => {
              return (
                <Fragment key={artistAccreditation['id']}>
                  <div className="separator" />
                  {renderAccreditation(artistAccreditation, 'artist')}
                  <div className="separator" />
                </Fragment>
              )
            })}
          </div>
        )}
      </Box>
    </div>
  )
}
