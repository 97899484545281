import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import WrapperUnauthAction from 'Components/WrapperUnauthAction'
import Button from 'NewVersion/components/UI/Buttons/Button'
import { TextInputField } from 'NewVersion/components/UI/Forms/TextInputField'
import Link from 'NewVersion/components/UI/Link/Link'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import 'NewVersion/styles/Login.scss'
import * as path from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { AUDIT_ACTIONS, postAudit } from 'utils/audit'
import { AuthContext } from './../../App'

function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setLoader] = useState(false)
  const [loginError, setLoginError] = useState(false)

  const { t } = useTranslation()

  const { setLastEdition } = useEdition()

  const navigate = useNavigate()
  const { dispatch } = React.useContext(AuthContext)
  const validateForm = () => {
    return false
  }

  const onSubmit = async (data) => {
    setLoader(true)
    data.preventDefault()
    // data.preventDefault()
    if (email === '' || password === '') {
      setLoginError(true)
      setLoader(false)
      return
    }
    try {
      const responseLogin = await fetchGuillotina({
        path: '@login',
        method: 'POST',
        data: {
          login: email,
          password: password,
        },
        checkError: true,
        unAuth: true,
      })
      const token_val = responseLogin.token

      const obj_pay = {
        user: email.toLowerCase(),
        token: token_val,
      }

      dispatch({
        type: 'LOGIN',
        payload: obj_pay,
      })

      setLastEdition()
      setLoader(false)
      postAudit(
        {
          action: AUDIT_ACTIONS.USER_LOGIN,
        },
        token_val
      )

      if (localStorage.reRoute !== 'login') {
        const rout = localStorage.reRoute
        navigate(rout)
      } else {
        navigate('/')
      }
    } catch (err) {
      setLoginError(true)
      setLoader(false)
    }
  }

  return (
    <WrapperUnauthAction>
      <section className="login">
        <div className="input-container">
          <h2 className="input-title">{t('Benvinguda')}</h2>
          <form className="inputs" onSubmit={onSubmit} data-test="formLoginTest">
            <TextInputField
              type="text"
              label={t('Correu electrònic')}
              onChange={({ target }) => setEmail(target.value)}
              error={loginError}
              dataTest="inputUsernameLoginTest"
              value={email}
            />
            <TextInputField
              type="password"
              label={t('password')}
              dataTest="inputPasswordLoginTest"
              onChange={({ target }) => setPassword(target.value)}
              error={loginError}
              value={password}
            />
            {loginError && <p className="error">{t('password_incorrect')}</p>}
            {!isLoading ? (
              <Button
                disabled={validateForm()}
                color="primary"
                type="submit"
                dataTest="btnLoginTest"
              >
                {t('Iniciar sessió')}
              </Button>
            ) : (
              <div style={{ height: '56px' }}>
                <LoadingComponent noClass={true} />
              </div>
            )}
            <div className="links">
              <Link href={path.REGISTER_PATH}>{t('Vull registrarme')}</Link>
              <Link href={path.RECOVERY_PATH}>{t('he oblidat la contrasenya')}</Link>
            </div>
          </form>
        </div>
        <div className="login-image" />
      </section>
    </WrapperUnauthAction>
  )
}

export default Login
