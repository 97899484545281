import { TableCell, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import { AcreditacioIcon } from 'NewVersion/icons/AcreditacioIcon'
import { FavouriteIcon } from 'NewVersion/icons/FavouriteIcon'
import { HomeIcon } from 'NewVersion/icons/HomeIcon'
import { UserIcon } from 'NewVersion/icons/UserIcon'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { downloadImageGuillotina } from 'utils/apiCalls'
import { AUDIT_ACTIONS } from 'utils/audit'
import { getEnsType } from 'utils/entityUtils'
import { cleanObjectIds } from 'utils/utils'
import Chips from '../UI/Chips/Chips'

const ProfessionalTableItemComponent = ({
  professional,
  isFavourite,
  addFavourite,
  removeFavourite,
  isFavouritesLoading,
  onClick,
  gridView,
}) => {
  const { t } = useTranslation()
  const [blobImg, setBlobImg] = React.useState(null)

  const getImg = async (responseData) => {
    try {
      const image = await downloadImageGuillotina(
        cleanObjectIds(responseData['@id']),
        'imatge/preview',
        'images'
      )
      if (image) {
        setBlobImg(image)
      }
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    if (professional && professional.imatge) {
      getImg(professional)
    }
  }, [professional])

  const renderFavouriteButton = () => {
    if (isFavourite) {
      return (
        <Chips
          text={t('delete')}
          icon={<FavouriteIcon width="16px" height="16px" />}
          dataTest={'deleteFavourite'}
          tooltip={t('Treure de preferits')}
          disabled={isFavouritesLoading}
          onClick={(event) => {
            removeFavourite(professional['@uid'], AUDIT_ACTIONS.REMOVE_PROFESSIONAL_FAVOURITE)
            event.stopPropagation()
          }}
        ></Chips>
      )
    }
    return (
      <Chips
        text={t('add')}
        icon={<FavouriteIcon width="16px" height="16px" outline={true} />}
        dataTest={'addFavourite'}
        tooltip={t('Afegir a preferits')}
        disabled={isFavouritesLoading}
        onClick={(event) => {
          addFavourite(professional['@uid'], AUDIT_ACTIONS.ADD_PROFESSIONAL_FAVOURITE)
          event.stopPropagation()
        }}
      ></Chips>
    )
  }

  const renderStyledName = (name) => {
    const captialize = (words) =>
      words
        .split(' ')
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(' ')
    return captialize(name.toLowerCase())
  }

  return (
    <>
      {!gridView && (
        <TableRow className={`table-row`}>
          <TableCell
            className="cursor-pointer"
            onClick={() => onClick(professional.parent_uuid)}
            data-test="tableCellProEnsTitle"
          >
            {professional.ens_title}
          </TableCell>
          <TableCell
            className="cursor-pointer"
            onClick={() => onClick(professional.uuid)}
            data-test="tableCellProName"
          >
            <b>{renderStyledName(professional.title)}</b>
          </TableCell>
          <TableCell data-test="tableCellProType">
            {t(getEnsType(professional.ens_type_name, professional.ens_tipus))}
          </TableCell>
          <TableCell>{renderFavouriteButton()}</TableCell>
        </TableRow>
      )}
      <Box className={`table-row-mobile professional-item display-sm`}>
        {gridView && (
          <Box
            className={'professional-item-imatge'}
            style={blobImg ? { backgroundImage: `url('${blobImg}')` } : {}}
          />
        )}
        <Box className={`professional-item-content`} flex={1}>
          <Box flex={1}>
            <Box
              className="line cursor-pointer"
              onClick={() => onClick(professional.parent_uuid)}
              data-test="tableCellProName"
            >
              {!gridView && <HomeIcon />}
              <p>{professional.ens_title}</p>
            </Box>
            <Box
              className="line cursor-pointer"
              onClick={() => onClick(professional.uuid)}
              data-test="tableCellProName"
            >
              {!gridView && <AcreditacioIcon />}
              <p>
                <b>{renderStyledName(professional.title)}</b>
              </p>
            </Box>
            <Box className="line" data-test="tableCellProType">
              {!gridView && <UserIcon />}
              <p>{t(getEnsType(professional.ens_type_name, professional.ens_tipus))}</p>
            </Box>
          </Box>
          {renderFavouriteButton()}
        </Box>
      </Box>
    </>
  )
}

export const ProfessionalTableItem = React.memo(ProfessionalTableItemComponent)
