import { useFormik } from 'formik'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AlertComponent } from 'Components/HelperComponents/AlertComponent'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { useEdition } from 'hooks/useEdition'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { get } from 'utils/objectUtils'

import { requiredError } from 'Components/Forms/FormErrors'
import { cleanObjectIds, getCurrentJWToken } from 'utils/utils'
import EditComponent from '../Fields/EditComponent'

import { useGuillotina } from 'hooks/useGuillotina'
import 'NewVersion/styles/Form.scss'
import { useParams } from 'react-router'
import { ACCREDITATION_TYPES_OBJ } from 'utils/accreditationUtils'
import { ACCREDITATIONS_SIZES_IMAGE } from 'utils/constants'
import Button from '../UI/Buttons/Button'

export const EditAccreditationForm = ({ accreditation, submitAction }) => {
  const { edition, loading } = useEdition()

  const accreditationTypeObj = useMemo(() => {
    if (accreditation) {
      return Object.values(ACCREDITATION_TYPES_OBJ).find((obj) => {
        return obj.interface === accreditation['@type']
      })
    }
    return null
  }, [accreditation])

  const {
    data: accreditationSchemaData = {},
    isLoading: accreditationSchemaLoading,
    error: accreditationSchemaError,
  } = useGuillotina({
    path: `@types/${accreditationTypeObj?.interface}`,
  })

  if (loading || accreditationSchemaLoading) {
    return <LoadingComponent />
  }

  return (
    <AccreditationFormContent
      edition={edition}
      accreditationSchema={{
        data: accreditationSchemaData,
        error: accreditationSchemaError,
        loading: accreditationSchemaLoading,
      }}
      submitAction={submitAction}
      accreditationTypeObj={accreditationTypeObj}
      accreditation={accreditation}
    />
  )
}

const AccreditationFormContent = ({
  edition,
  accreditationSchema,
  submitAction,
  accreditationTypeObj,
  accreditation,
}) => {
  const token = getCurrentJWToken()
  const { entityId, accreditationId } = useParams()
  const { t } = useTranslation()
  const [submitError, setSubmitError] = useState({
    hasError: false,
    message: '',
  })
  const [isLoading, setIsLoading] = useState(false)

  const handleAccreditationError = (error) => {
    console.error(`Edit Accreditation form error: ${error}`)
    if (error.response && error.response.status === 412) {
      if (get(error, 'response.data.code') === 'invalid-email') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('invalid_email')}`,
        })
      } else {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('error_create_or_update_ens')}`,
        })
      }
    } else {
      setSubmitError({
        type: 'danger',
        message: `Error: ${t('error_generic')}`,
      })
    }
    window.scrollTo(0, 0)
  }

  const handleAccreditationImageError = (error) => {
    console.error(`Edit Accreditation image form error: ${error}`)
    setSubmitError({
      hasError: true,
      message: `Error: ${t('error_uploading_image')}`,
    })
  }

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true)

      const dataToSend = { ...values }
      if (dataToSend['imatge'] !== null) {
        delete dataToSend['imatge']
      }
      delete dataToSend['correu']

      const path = `${edition}/${entityId}/${accreditationId}`
      fetchGuillotina({
        path: path,
        method: 'PATCH',
        data: dataToSend,
        token: token,
      })
        .then(async (response) => {
          if (response['code'] && response['message']) {
            handleAccreditationError(response)
            return null
          }

          if (values['imatge'] && values['imatge'] instanceof File) {
            let filename = undefined
            if (values['imatge'].filename) {
              filename = values['imatge'].filename
            } else if (values['imatge'].name) {
              filename = values['imatge'].name
            }

            fetchGuillotina({
              path: `${path}/@upload/imatge`,
              method: 'PATCH',
              token: token,
              headers: {
                Accept: 'application/json',
                'X-UPLOAD-EXTENSION': values['imatge'].type.split('/')[1],
                // 'X-UPLOAD-SIZE': values['imatge'].size,
                'X-UPLOAD-FILENAME-B64': btoa(unescape(encodeURIComponent(filename))),
                'Content-Type': values['imatge'].type,
              },
              notStringify: true,
              data: values['imatge'],
            })
              .then(async () => {
                for (let i = 0; i < ACCREDITATIONS_SIZES_IMAGE.length; i++) {
                  await fetchGuillotina({
                    path: `${path}/@images/imatge/${ACCREDITATIONS_SIZES_IMAGE[i]}`,
                    method: 'PATCH',
                    token: token,
                    headers: {
                      'X-UPLOAD-EXTENSION': values['imatge'].type.split('/')[1],
                      // 'X-UPLOAD-SIZE': values['imatge'].size,
                      'X-UPLOAD-FILENAME-B64': btoa(unescape(encodeURIComponent(filename))),
                      'Content-Type': values['imatge'].type,
                    },
                  })
                }
                setIsLoading(false)
                if (submitAction) {
                  submitAction()
                }
              })
              .catch((error) => {
                setIsLoading(false)
                if (error) handleAccreditationImageError(error)
                return
              })
          } else {
            setIsLoading(false)
            if (submitAction) {
              submitAction()
            }
          }
        })
        .catch((error) => {
          if (error) handleAccreditationError(error)
          return null
        })
    } catch (error) {
      console.error(`Edit Accreditation form error: ${error}`)
      setIsLoading(false)
    }
  }

  const getInitialValues = React.useMemo(() => {
    if (accreditation) {
      const result = {}
      const allFields = []

      accreditationTypeObj?.orderFieldsForm.forEach((item) => {
        if (item.fields && Array.isArray(item.fields)) {
          allFields.push(...item.fields)
        }
      })

      allFields.map((field) => (result[field] = accreditation[field]))

      return result
    }
  }, [accreditation])

  const formik = useFormik({
    initialValues: getInitialValues,
    validateOnChange: false,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {}
      accreditationTypeObj['requiredFields'].forEach((key) => {
        if (key !== 'declaracio_acceptar') {
          if (values['primer_cop_fira'] === false && key in values) {
            // Do nothing
          } else {
            if (!(key in values) || !values[key]) {
              errors[key] = requiredError()
            }
          }
        }
      })
      return errors
    },
  })

  const renderField = (key) => {
    if (get(accreditationSchema, `data.properties[${key}]`, null)) {
      const value = accreditationSchema.data.properties[key]
      return (
        <EditComponent
          key={key}
          name={key}
          label={t(key)}
          schema={value}
          description={value.description ? t(`${key}_description`) : null}
          value={formik.values?.[key]}
          required={(accreditationTypeObj['requiredFields'] ?? []).includes(key)}
          onBlur={() => {
            formik.setFieldTouched(key)
          }}
          onChange={(ev) => {
            formik.setFieldValue(key, ev)
            formik.validateForm()
          }}
          error={formik.touched[key] && formik.errors[key]}
          dataTest={`inputFieldTest_${key}`}
          disabled={key === 'correu'}
          path={accreditation ? cleanObjectIds(accreditation['@id']) : undefined}
        />
      )
    }
  }

  const renderColumns = (item, indexItem) => {
    const middleIndex = Math.ceil(item['fields'].length / 2)
    return (
      <div className="fragment" key={`accreditationFormSection_${item['title']}_${indexItem}`}>
        <div className="fragmentColumn">
          {item['fields'].slice(0, middleIndex).map((key) => renderField(key))}
        </div>
        <div className="fragmentColumn">
          {item['fields'].slice(middleIndex).map((key) => renderField(key))}
        </div>
      </div>
    )
  }

  return (
    <>
      {submitError.hasError && submitError.message && (
        <AlertComponent type="danger"> {submitError.message}</AlertComponent>
      )}
      <form
        className="Form"
        onSubmit={(values) => formik.handleSubmit(values)}
        data-test="formTest"
      >
        <div className="acreditacio">
          <h5>{t('acreditacio')}</h5>
          {accreditationSchema &&
            accreditationSchema.data &&
            !accreditationSchema.loading &&
            accreditationTypeObj?.['orderFieldsForm']
              .filter((item) => item.title !== 'declaracio_fieldset')
              .map((item, indexItem) => {
                return renderColumns(item, indexItem)
              })}
        </div>
        <div>
          {isLoading && <LoadingComponent />}
          {!isLoading && (
            <div className="footer-buttons">
              <Button type="submit" data-test="btnSubmitFormTest" disabled={isLoading}>
                {t('save')}
              </Button>
            </div>
          )}
        </div>
      </form>
    </>
  )
}
