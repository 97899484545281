import { get } from './objectUtils'

export const getVocabularies = (ensTypeObj) => {
  const defaultValues = [
    {
      vocabulary: 'countriesfm',
    },
    {
      vocabulary: 'comunitat_autonoma',
    },
    {
      vocabulary: 'comarca',
    },
  ]

  if (get(ensTypeObj, 'interface', '') === ENS_TYPES_OBJ.professional.interface) {
    defaultValues.push({ vocabulary: ensTypeObj.tipologia })
    defaultValues.push({ vocabulary: 'descompte_membres' })
    defaultValues.push({ vocabulary: 'vehicle' })
  }
  if (get(ensTypeObj, 'interface', '') === ENS_TYPES_OBJ.premsa.interface) {
    defaultValues.push({ vocabulary: ensTypeObj.tipologia })
    defaultValues.push({ vocabulary: 'vehicle' })
  }
  if (
    get(ensTypeObj, 'interface', '') === ENS_TYPES_OBJ.premsa.interface ||
    get(ensTypeObj, 'interface', '') === ENS_TYPES_OBJ.professional.interface
  ) {
    defaultValues.push({ vocabulary: 'interes_artistic' })
    defaultValues.push({ vocabulary: 'vehicle' })
  }

  return defaultValues
}

export const commonOrderFieldsForm = [
  'adreca',
  'codi_postal',
  'poblacio',
  'pais',
  'comunitat_autonoma',
  'comarca',
  'telefon',
  'mobil',
  'correu',
  'web',
  'facebook',
  'twitter',
  'instagram',
  'linkedin',
  'youtube',
  'idioma',
]

export const commonRequiredFields = [
  'title',
  'adreca',
  'codi_postal',
  'poblacio',
  'pais',
  'telefon',
  'correu',
  'idioma',
  'declaracio_acceptar',
  'comunitat_autonoma',
  'comarca',
]

export const ENS_TYPES = {
  artist: 'Ens',
  manager: 'Ens',
  proposta: 'Proposta',
  professional: 'EnsProfessional',
  press: 'EnsPremsa',
  service: 'EnsServeis',
}

export const getEnsType = (type_name, tipus) => {
  if (type_name === ENS_TYPES.artist || type_name === ENS_TYPES.manager) {
    if (tipus === 'artista') {
      return ENS_TYPES_OBJ.artista.text
    } else {
      return ENS_TYPES_OBJ.representant.text
    }
  } else if (type_name === ENS_TYPES.proposta) {
    return ENS_TYPES_OBJ.proposta.text
  } else if (type_name === ENS_TYPES.press) {
    return ENS_TYPES_OBJ.premsa.text
  } else if (type_name === ENS_TYPES.professional) {
    return ENS_TYPES_OBJ.professional.text
  } else if (type_name === ENS_TYPES.service) {
    return ENS_TYPES_OBJ.serveis.text
  }
}

export const ENS_TYPES_OBJ = {
  artista: {
    text: 'artista',
    ens_type_name: 'Ens',
    ens_tipus: 'artista',
    tipologia: '',
    titleForm: 'entity_artist_title',
    labelName: 'identificacio_artista_nom',
    haveDescription: false,
    interface: ENS_TYPES.artist,
    orderFieldsForm: [
      {
        title: 'entity_data',
        fields: [
          'title',
          'nom_fiscal',
          'nif',
          'telefon',
          'mobil',
          'correu',
          'nom_contacte',
          'cognoms_contacte',
          'adreca',
          'codi_postal',
          'poblacio',
          'pais',
          'comunitat_autonoma',
          'comarca',
        ],
      },
      {
        title: 'entity_enllacos',
        fields: ['web', 'facebook', 'twitter', 'instagram', 'linkedin', 'youtube'],
      },
      {
        title: 'entity_informacio_adicional',
        fields: ['idioma'],
      },
      {
        title: 'declaracio_fieldset',
        fields: ['declaracio_acceptar'],
      },
    ],
    requiredFields: [
      ...commonRequiredFields,
      'nom_fiscal',
      'nif',
      'nom_contacte',
      'cognoms_contacte',
    ],
    totalAccreditationAttribute: 'total_acreditacions_artista',
    canAddAccreditation: true,
    canAddStand: true,
    canAddShop: false,
  },
  representant: {
    text: 'representant',
    ens_type_name: 'Ens',
    ens_tipus: null,
    tipologia: '',
    titleForm: 'entity_manager_title',
    labelName: 'identificacio_manager_nom',
    haveDescription: false,
    interface: ENS_TYPES.manager,
    orderFieldsForm: [
      {
        title: 'entity_data',
        fields: [
          'title',
          'nom_fiscal',
          'nif',
          'telefon',
          'mobil',
          'correu',
          'nom_contacte',
          'cognoms_contacte',
          'adreca',
          'codi_postal',
          'poblacio',
          'pais',
          'comunitat_autonoma',
          'comarca',
        ],
      },
      {
        title: 'entity_enllacos',
        fields: ['web', 'facebook', 'twitter', 'instagram', 'linkedin', 'youtube'],
      },
      {
        title: 'entity_informacio_adicional',
        fields: ['idioma', 'tipologia', 'interes_artistic'],
      },
      {
        title: 'declaracio_fieldset',
        fields: ['declaracio_acceptar'],
      },
    ],
    requiredFields: [
      ...commonRequiredFields,
      'nom_fiscal',
      'nif',
      'nom_contacte',
      'cognoms_contacte',
    ],
    totalAccreditationAttribute: 'total_acreditacions_representants',
    canAddAccreditation: true,
    canAddStand: true,
    canAddShop: false,
  },
  proposta: {
    text: 'proposta',
    tipologia: '',
    titleForm: 'entity_proposta_title',
    labelName: 'identificacio_proposta_nom',
    haveDescription: false,
    interface: ENS_TYPES.proposta,
    orderFieldsForm: [
      {
        title: 'proposta_espectacle',
        fields: [
          'nom',
          'ambits',
          'altres_ambits',
          'durada',
          'tipus',
          'caracteristiques',
          'public',
          'franja',
          'idioma',
          'estrena',
          'dataestrena',
          'claus',
          'claus_altres',
          'actuants',
          'tecnics',
          'manager',
          'localitat',
          'declaracio_drets',
          'altres_drets',
          'muntatge',
          'desmuntatge',
        ],
      },
      {
        title: 'proposta_artista',
        fields: ['pais', 'comunitat_autonoma', 'comarca'],
      },
      {
        title: 'proposta_economia_fieldset',
        fields: ['caixet', 'proposta_economica', 'nombre_actuacions'],
      },
      {
        title: 'economia',
        fields: [
          'enllacos2',
          'enllacos',
          'enllacos3',
          'dossier_artistic',
          'imatge_companyia',
          'fitxa_artistica',
          'imatge_companyia2',
          'fitxa_tecnica',
        ],
      },
      {
        title: 'declaracio_fieldset',
        fields: ['declaracio_acceptar'],
      },
    ],
    requiredFields: [
      'nom',
      'ambits',
      'durada',
      'tipus',
      'caracteristiques',
      'public',
      'franja',
      'idioma',
      'estrena',
      'dataestrena',
      'claus',
      'claus_altres',
      'actuants',
      'localitat',
      'tecnics',
      'muntatge',
      'manager',
      'desmuntatge',
      'declaracio_drets',
      'altres_drets',
      'pais',
      'comunitat_autonoma',
      'comarca',
      'caixet',
      'proposta_economica',
      'nombre_actuacions',
      'dossier_artistic',
      'fitxa_artistica',
      'fitxa_tecnica',
    ],
    totalAccreditationAttribute: 'total_acreditacions_representants',
    canAddAccreditation: true,
    canAddStand: true,
    canAddShop: false,
  },
  professional: {
    text: 'professional',
    ens_type_name: 'EnsProfessional',
    ens_tipus: null,
    tipologia: 'tipologia_professional',
    titleForm: 'entity_professional_title',
    subtitleForm: 'entity_professional_subtitle',
    labelName: 'identificacio_ens',
    haveDescription: true,
    interface: ENS_TYPES.professional,
    orderFieldsForm: [
      {
        title: 'entity_data',
        subtitle: 'entity_subtitle',
        fields: [
          'title',
          'nom_fiscal',
          'nif',
          'adreca',
          'codi_postal',
          'poblacio',
          'pais',
          'comunitat_autonoma',
          'comarca',
          'telefon',
          'mobil',
          'correu',
        ],
      },
      {
        title: 'entity_enllacos',
        fields: ['web', 'facebook', 'twitter', 'instagram', 'linkedin', 'youtube'],
      },
      {
        title: 'entity_informacio_adicional',
        fields: ['idioma', 'tipologia', 'interes_artistic'],
      },
      {
        title: 'entity_promotions',
        fields: ['descompte_membres', 'descompte_promocional'],
      },
      {
        title: 'entity_environment',
        subtitle: 'subtitle_entity_environment',
        fields: [
          'municipi',
          'mitja_transport',
          'tipus_combustible',
          'total_cops_trajecte',
          'total_vehicles',
        ],
      },
      {
        title: 'declaracio_fieldset',
        fields: ['declaracio_acceptar'],
      },
    ],
    requiredFields: [...commonRequiredFields, 'tipologia', 'interes_artistic', 'nom_fiscal', 'nif'],
    totalAccreditationAttribute: 'total_acreditacions_professionals',
    canAddAccreditation: true,
    canAddStand: true,
    canAddShop: false,
    functionNameCanCreateEntity: 'canCreateEnsProfessionals',
  },
  premsa: {
    text: 'premsa',
    ens_type_name: 'EnsPremsa',
    ens_tipus: null,
    tipologia: 'tipologia_premsa',
    titleForm: 'entity_press_title',
    subtitleForm: 'entity_press_subtitle',
    labelName: 'identificacio_press_nom',
    haveDescription: true,
    interface: ENS_TYPES.press,
    orderFieldsForm: [
      {
        title: 'entity_data',
        subtitle: 'entity_subtitle',
        fields: [
          'title',
          'nom_fiscal',
          'nif',
          'adreca',
          'codi_postal',
          'poblacio',
          'pais',
          'comunitat_autonoma',
          'comarca',
          'telefon',
          'mobil',
          'correu',
        ],
      },
      {
        title: 'entity_enllacos',
        fields: ['web', 'facebook', 'twitter', 'instagram', 'linkedin', 'youtube'],
      },
      {
        title: 'entity_informacio_adicional',
        fields: ['idioma', 'tipologia', 'interes_artistic'],
      },
      {
        title: 'entity_environment',
        subtitle: 'subtitle_entity_environment',
        fields: [
          'municipi',
          'mitja_transport',
          'tipus_combustible',
          'total_cops_trajecte',
          'total_vehicles',
        ],
      },
      {
        title: 'declaracio_fieldset',
        fields: ['declaracio_acceptar'],
      },
    ],
    requiredFields: [...commonRequiredFields, 'tipologia', 'interes_artistic'],
    totalAccreditationAttribute: 'total_acreditacions_premsa',
    canAddAccreditation: true,
    canAddStand: false,
    canAddShop: false,
    functionNameCanCreateEntity: 'canCreateEnsPremsa',
  },
  serveis: {
    text: 'serveis',
    ens_type_name: 'EnsServeis',
    ens_tipus: null,
    tipologia: 'tipologia_serveis',
    titleForm: 'entity_services_title',
    labelName: 'identificacio_ens',
    haveDescription: false,
    interface: ENS_TYPES.service,
    orderFieldsForm: [
      {
        title: 'entity_data',
        subtitle: 'entity_subtitle',
        fields: [
          'title',
          'nom_fiscal',
          'nif',
          'adreca',
          'codi_postal',
          'poblacio',
          'pais',
          'comunitat_autonoma',
          'comarca',
          'telefon',
          'mobil',
          'correu',
        ],
      },
      {
        title: 'entity_enllacos',
        fields: ['web', 'facebook', 'twitter', 'instagram', 'linkedin', 'youtube'],
      },
      {
        title: 'entity_informacio_adicional',
        fields: ['idioma', 'tipologia', 'interes_artistic'],
      },
      {
        title: 'entity_environment',
        subtitle: 'subtitle_entity_environment',
        fields: [
          'municipi',
          'mitja_transport',
          'tipus_combustible',
          'total_cops_trajecte',
          'total_vehicles',
        ],
      },
      {
        title: 'declaracio_fieldset',
        fields: ['declaracio_acceptar'],
      },
    ],
    requiredFields: [...commonRequiredFields, 'tipologia', 'nom_fiscal', 'nif'],
    totalAccreditationAttribute: null,
    canAddAccreditation: false,
    canAddStand: false,
    canAddShop: true,
    functionNameCanCreateEntity: 'canCreateEnsServeis',
  },
}
