import { AlertComponent, LoadingComponent } from 'Components/HelperComponents'
import EntityAndAccreditationsForm from 'NewVersion/components/Forms/EntityAndAccreditationsForm'
import EntityForm from 'NewVersion/components/Forms/EntityForm'
import { GoBackButton } from 'NewVersion/components/UI/GoBack/GoBackButton'
import { ModalInfo } from 'NewVersion/components/UI/Modals/Modal'
import { NewInscriptionIcon } from 'NewVersion/icons/NewInscriptionIcon'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { ACCOUNT_PATH, PAYMENT } from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { ACCREDITATION_TYPES_OBJ } from 'utils/accreditationUtils'
import { OBJECT_TYPE_HAVE_RATE } from 'utils/constants'
import { ENS_TYPES_OBJ } from 'utils/entityUtils'
import { get, partition } from 'utils/objectUtils'
import { cleanObjectIds, getCurrentJWToken } from 'utils/utils'

const AddPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { type: entityType } = useParams()
  const [submitModal, setSubmitModal] = useState(false)
  const { currentEdition, loading: loadingCurrentEdition, edition } = useEdition()
  const token = getCurrentJWToken()

  const [submitError, setSubmitError] = useState({
    hasError: false,
    message: '',
  })

  const accreditationTypeObj = () => {
    if (entityType) {
      if (entityType === ENS_TYPES_OBJ.premsa.text) {
        return ACCREDITATION_TYPES_OBJ.premsa
      }
      return ACCREDITATION_TYPES_OBJ.professional
    }
    return null
  }

  const accreditationsIncluded = () => {
    return entityType !== ENS_TYPES_OBJ.serveis.text
  }

  const handleEntityError = (error) => {
    console.error(`Entity form error: ${error}`)
    if (error.response && error.response.status === 412) {
      if (get(error, 'response.data.code') === 'already-exists') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('title_already_exists')}`,
        })
      } else if (get(error, 'response.data.code') === 'invalid-email') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('invalid_email')}`,
        })
      } else if (get(error, 'response.data.code') === 'promo-code-not-exist') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('promo_code_not_exist')}`,
        })
      } else {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('error_create_or_update_ens')}`,
        })
      }
    } else {
      setSubmitError({
        hasError: true,
        message: `Error: ${t('error_generic')}`,
      })
    }
    window.scrollTo(0, 0)
  }

  const submitAction = () => {
    setSubmitModal(true)
  }

  const patchFile = (values = {}, path = '', field) => {
    let filename = undefined
    if (values.filename) {
      filename = values.filename
    } else if (values.name) {
      filename = values.name
    }

    return fetchGuillotina({
      path: path + '/@upload/' + field,
      method: 'PATCH',
      token: token,
      headers: {
        'X-UPLOAD-EXTENSION': values.type.split('/')[1],
        // 'X-UPLOAD-SIZE': values.size,
        'X-UPLOAD-FILENAME-B64': btoa(unescape(encodeURIComponent(filename))),
        'Content-Type': values.type,
      },
      notStringify: true,
      data: values,
    })
  }

  const handleSubmitParada = async (values, entity) => {
    console.log(values, entity)
    const data = Object.assign({}, values, {
      '@type': 'Parada',
      tarifa: currentEdition.getCurrentRateByType(OBJECT_TYPE_HAVE_RATE.service),
      idioma: entity.idioma,
    })
    if (data['imatge'] !== null) {
      delete data['imatge']
    }
    if (data['fitxer_asseguranca'] !== null) {
      delete data['fitxer_asseguranca']
    }
    if (data['fitxer_dni'] !== null) {
      delete data['fitxer_dni']
    }
    if (data['fitxer_seguretat_social'] !== null) {
      delete data['fitxer_seguretat_social']
    }
    try {
      let path = cleanObjectIds(entity['@id'])
      const responsePost = await fetchGuillotina({ path: path, method: 'POST', data, token })
      path = `${path}/${responsePost['@name']}`
      // Afegir documentacio
      if (values['fitxer_asseguranca'] && values['fitxer_asseguranca'] instanceof File) {
        await patchFile(values['fitxer_asseguranca'], path, 'fitxer_asseguranca')
      }
      if (values['fitxer_dni'] && values['fitxer_dni'] instanceof File) {
        await patchFile(values['fitxer_dni'], path, 'fitxer_dni')
      }
      if (values['fitxer_seguretat_social'] && values['fitxer_seguretat_social'] instanceof File) {
        await patchFile(values['fitxer_seguretat_social'], path, 'fitxer_seguretat_social')
      }
      if (values['imatge'] && values['imatge'] instanceof File) {
        await patchFile(values['imatge'], path, 'imatge')
      }
    } catch (error) {
      console.error(`Fira Passeig form error: ${error}`)
      handleEntityError(error)
      return false
    }
    return true
  }

  const handleSubmitFiraPasseig = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)

      const [dataShopValues, dataEntityValues] = partition(values, (e) => e.includes('shop_'))

      const token = getCurrentJWToken()

      const dataEntity = Object.assign({}, dataEntityValues, {
        '@type': ENS_TYPES_OBJ[entityType].interface,
        tarifa: currentEdition.getCurrentRateByType(OBJECT_TYPE_HAVE_RATE.service),
      })

      fetchGuillotina({ path: `${edition}`, method: 'POST', data: dataEntity, token: token })
        .then(async (response) => {
          if (response) {
            if (response['code']) {
              const code = response['code']
              console.error(`Fira Passeig form error: ${code}`)
              handleEntityError(response)
              return null
            }
            const data = Object.keys(dataShopValues).reduce(
              (res, key) => ({
                ...res,
                [key.replace(`shop_`, '')]: dataShopValues[key],
              }),
              {}
            )
            const success = await handleSubmitParada(data, response)

            setSubmitting(false)
            if (submitAction && success) {
              submitAction()
            }
          }
        })
        .catch((error) => {
          console.error(`Fira Passeig form error: ${error}`)
          handleEntityError(error)
          setSubmitting(false)
          return
        })
    } catch (error) {
      console.error(`Fira Passeig form error: ${error}`)
    }
  }

  const canCreateEntity = useMemo(() => {
    return currentEdition[ENS_TYPES_OBJ[entityType]['functionNameCanCreateEntity']]()
  }, [currentEdition, entityType])

  return (
    <div className="page-wrapper">
      <GoBackButton onClick={() => navigate(-1)} text={t('Tornar enrere 2')} />
      <div onClick={() => navigate(-1)} className="cursor-pointer">
        <h4>{t(ENS_TYPES_OBJ[entityType].titleForm, { year: edition })}</h4>
      </div>

      {loadingCurrentEdition && <LoadingComponent />}
      {!loadingCurrentEdition && !canCreateEntity && (
        <AlertComponent type="danger"> {t('registration_period_is_closed')}</AlertComponent>
      )}
      {!loadingCurrentEdition && canCreateEntity && (
        <>
          {ENS_TYPES_OBJ[entityType].subtitleForm && (
            <p className="body-bold">
              {t(ENS_TYPES_OBJ[entityType].subtitleForm, {
                total_inscripcio: currentEdition.getConfigData().preu_defecte_professionals,
                total_acreditats: currentEdition.getConfigData().total_acreditacions_professionals,
                preu_acreditacio: currentEdition.getConfigData().preu_acreditacio,
              })}
            </p>
          )}
          {submitError.hasError && submitError.message && (
            <AlertComponent type="danger"> {submitError.message}</AlertComponent>
          )}
          <ModalInfo
            open={submitModal}
            setOpen={setSubmitModal}
            handleOk={() => navigate(ACCOUNT_PATH)}
            title={t('inscripcio_realitzada')}
            text={t('confirmacio_per_email')}
            icon={<NewInscriptionIcon fill={'#273BF3'} />}
          />
          {accreditationsIncluded() ? (
            <EntityAndAccreditationsForm
              ensTypeObj={ENS_TYPES_OBJ[entityType]}
              accreditationTypeObj={accreditationTypeObj()}
              submitAction={async (paymentUuid, promoCode) => {
                if (entityType === 'professional') {
                  if (promoCode) {
                    setSubmitModal(true)
                    return
                  }
                  navigate(`${PAYMENT}/info/${paymentUuid}`)
                } else if (entityType === 'premsa') {
                  setSubmitModal(true)
                }
              }}
            />
          ) : (
            <EntityForm
              ensTypeObj={ENS_TYPES_OBJ[entityType]}
              handleSubmit={handleSubmitFiraPasseig}
            />
          )}
        </>
      )}
    </div>
  )
}

export default AddPage
