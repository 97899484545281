import * as React from 'react'
import { Navigate, Route, Routes as RoutesReact, useLocation } from 'react-router-dom'
import usePushNotifications from '../usePushNotifications'

import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import Login from 'NewVersion/pages/Login'
import NewPasswordPage from 'NewVersion/pages/NewPasswordPage'
import RecoveryPassword from 'NewVersion/pages/RecoveryPassword'
import Register from 'NewVersion/pages/Register'
import NotAllowPage from 'Pages/NotAllowPage'
import NotFound from 'Pages/NotFound'
import { getCurrentEditionLocalStorage } from 'context/EditionContext'
import { useEdition } from 'hooks/useEdition'

import { useGuillotina } from 'hooks/useGuillotina'
import { AUDIT_ACTIONS, postAudit } from 'utils/audit'
import { getCurrentJWToken } from 'utils/utils'
import { PathToPageMap } from './PathToPageMap'
import * as path from './Paths'

export const Routes = () => {
  const location = useLocation()
  const { currentEdition } = useEdition()
  const [canAccesInfo, setCanAccesInfo] = React.useState({
    data: undefined,
    loading: false,
  })
  const editionLocalStorage = React.useMemo(() => {
    return getCurrentEditionLocalStorage()
  }, [])

  const { onClickSusbribeToPushNotification } = usePushNotifications()
  const isSupported = () =>
    'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window

  React.useEffect(() => {
    // IF granted, localstorage.accepteNotif undefined i localstorage.denied undefined, envia subs
    try {
      if (isSupported) {
        if (
          localStorage.acceptNotifications === undefined &&
          localStorage.deniedNotifications === undefined &&
          Notification.permission === 'granted'
        ) {
          // notificacions acceptades, no el tenim subscrit, el subscribim
          localStorage.setItem('acceptNotifications', false)
          onClickSusbribeToPushNotification()
        }
      }
    } catch (error) {
      console.error(error)
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
  }, [location, onClickSusbribeToPushNotification])

  React.useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(location.search))
    postAudit({
      action: AUDIT_ACTIONS.PAGE_VIEW,
      path: location.pathname,
      searchParams: params,
    })
  }, [location])

  // check get edition, to validate user token
  useGuillotina({
    path: editionLocalStorage && getCurrentJWToken() ? editionLocalStorage : null,
    token: getCurrentJWToken(),
  })

  React.useEffect(() => {
    if (currentEdition === undefined && canAccesInfo.data !== undefined) {
      setCanAccesInfo({ data: undefined, loading: false })
    }
    async function load() {
      setCanAccesInfo((prev) => ({ ...prev, loading: true }))
      try {
        const result = await currentEdition.canAccesToInfo()
        setCanAccesInfo({ data: result, loading: false })
      } catch (err) {
        setCanAccesInfo({ data: false, loading: false })
      }
    }

    if (currentEdition && canAccesInfo.data === undefined && !canAccesInfo.loading) {
      load()
    }
  }, [canAccesInfo, setCanAccesInfo, currentEdition])

  if ((!currentEdition || canAccesInfo.loading) && localStorage.isAuthenticated) {
    return <LoadingComponent />
  }

  if (!canAccesInfo.data && localStorage.isAuthenticated) {
    return <NotAllowPage />
  }

  if (localStorage.isAuthenticated === 'true') {
    return (
      <RoutesReact>
        {currentEdition &&
          currentEdition.getConfigRoutes().map((route) => {
            if (route.redirect) {
              return (
                <Route
                  key={`${route.from}-${route.to}`}
                  path={route.from}
                  element={<Navigate replace to={route.to} />}
                />
              )
            }
            return (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                element={PathToPageMap[route.path]}
              />
            )
          })}
        <Route path="*" element={<NotFound />} />
      </RoutesReact>
    )
  } else {
    // We check if route had something
    if (location.pathname !== 'login') {
      localStorage.setItem('reRoute', location.pathname)
    }

    return (
      <RoutesReact>
        <Route path={path.LOGIN_PATH} element={<Login />} />
        <Route path={path.REGISTER_PATH} element={<Register />} />
        <Route path={path.RECOVERY_PATH} element={<RecoveryPassword />} />
        <Route path={path.NEW_PASSWORD_PATH} element={<NewPasswordPage />} />
        <Route path="*" element={<Navigate replace to={path.LOGIN_PATH} />} />
      </RoutesReact>
    )
  }
}
