import { Dialog } from '@mui/material'
import { Box } from '@mui/system'
import { LoadingComponent } from 'Components/HelperComponents'
import { CrossIcon } from 'NewVersion/icons/CrossIcon'
import { EmailIcon } from 'NewVersion/icons/EmailIcon'
import { FacebookIcon } from 'NewVersion/icons/FacebookIcon'
import { FavouriteIcon } from 'NewVersion/icons/FavouriteIcon'
import { InstagramIcon } from 'NewVersion/icons/InstagramIcon'
import { LinkIcon } from 'NewVersion/icons/LinkIcon'
import LinkedinIcon from 'NewVersion/icons/LinkedinIcon'
import { TwitterIcon } from 'NewVersion/icons/TwitterIcon'
import YoutubeIcon from 'NewVersion/icons/YoutubeIcon'
import 'NewVersion/styles/ProfessionalDialog.scss'
import { useFavourites } from 'hooks/useFavourites'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { downloadImageGuillotina } from 'utils/apiCalls'
import { AUDIT_ACTIONS } from 'utils/audit'
import { cleanObjectIds } from 'utils/utils'

const AccreditationPersonDetailInfoComponent = ({ person, setPersonNull, onClose, isOpen }) => {
  const { t } = useTranslation()
  const { favouiteIds, addFavourite, removeFavourite } = useFavourites()
  const [blobImg, setBlobImg] = useState(null)
  const [loading, setLoading] = useState(true)
  const isFavourite = favouiteIds && person && favouiteIds.includes(person['@uid'])

  const getImg = async () => {
    try {
      const image = await downloadImageGuillotina(
        cleanObjectIds(person['@id']),
        'imatge/preview',
        'images'
      )
      if (image) {
        setBlobImg(image)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!blobImg && person) {
      setLoading(true)
      getImg()
    }
  }, [person])

  const renderInfo = (title, value) => (
    <Box className="professional-dialog-content-info">
      <p className="body-2-bold">{title}</p>
      {value}
    </Box>
  )

  const getSocialLinks = () => {
    return (
      <div className="professional-social-links">
        {person.facebook && (
          <a target="_blank" href={person.facebook} rel="noreferrer">
            <FacebookIcon />
          </a>
        )}
        {person.instagram && (
          <a target="_blank" href={person.instagram} rel="noreferrer">
            <InstagramIcon />
          </a>
        )}
        {person.twitter && (
          <a target="_blank" href={person.twitter} rel="noreferrer">
            <TwitterIcon />
          </a>
        )}
        {person.youtube && (
          <a target="_blank" href={person.youtube} rel="noreferrer">
            <YoutubeIcon />
          </a>
        )}
        {person.linkedin && (
          <a target="_blank" href={person.linkedin} rel="noreferrer">
            <LinkedinIcon />
          </a>
        )}
      </div>
    )
  }

  if (person === null) {
    return <></>
  }

  const handleClose = () => {
    onClose()
    setPersonNull()
    setBlobImg(null)
    setLoading(true)
  }

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={() => handleClose()}
      classes={{ paper: 'professional-dialog' }}
    >
      {loading ? (
        <Box className={'professional-dialog-content professional-dialog-loading'}>
          <LoadingComponent />
        </Box>
      ) : (
        <>
          <Box
            className={'professional-dialog-imatge'}
            style={blobImg ? { backgroundImage: `url('${blobImg}')` } : {}}
          />
          <Box className={'professional-dialog-content'}>
            <Box
              className="professional-dialog-close-icon"
              onClick={() => handleClose()}
              data-test="closeDialog-icon"
            >
              <CrossIcon />
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <h4 className="hide-sm" data-test="dialog-title">
                {person.nom ? `${person.nom} ${person.cognoms}` : person.title}
              </h4>
              <Box className={'professional-dialog-content-buttons'}>
                {person.web && (
                  <a href={`${person.web}`} rel="noreferrer">
                    <LinkIcon width="24px" height="24px" />
                  </a>
                )}
                {person.correu && (
                  <a href={`mailto:${person.correu}`} rel="noreferrer">
                    <EmailIcon width="24px" height="24px" />
                  </a>
                )}
                <Box
                  onClick={() => {
                    if (isFavourite) {
                      removeFavourite(person['@uid'], AUDIT_ACTIONS.REMOVE_PROFESSIONAL_FAVOURITE)
                    } else {
                      addFavourite(person['@uid'], AUDIT_ACTIONS.ADD_PROFESSIONAL_FAVOURITE)
                    }
                  }}
                >
                  <FavouriteIcon width="24px" height="24px" outline={!isFavourite} />
                </Box>
              </Box>
            </Box>
            <h4 className="display-sm">{`${person.nom} ${person.cognoms}`}</h4>
            <Box className="divider" mt={'4px'} mb={2} />
            {person.carrec && renderInfo(t('Càrrec'), <p>{person.carrec}</p>)}
            {(person.codi_postal || person.pais) &&
              renderInfo(
                t('adreca'),
                <p>{`${person.codi_postal} ${person.comarca ? t(person.comarca) : ''} ${
                  person.comunitat_autonoma ? t(person.comunitat_autonoma) : ''
                } ${t(person.pais)}`}</p>
              )}
            <Box className="split-content">
              {person.telefon &&
                renderInfo(t('telefon'), <a href={'tel:${telefon}'}>{person.telefon}</a>)}
              {person.correu &&
                renderInfo(t('correu'), <a href={`mailto:${person.correu}`}>{person.correu}</a>)}
            </Box>
            <Box className="divider" mt={1} my={2} />
            {getSocialLinks()}
          </Box>
        </>
      )}
    </Dialog>
  )
}

export const AccreditationPersonDetailInfo = React.memo(AccreditationPersonDetailInfoComponent)
