import jwt from 'jwt-decode'

const returnGetData = (response, mode = 'generic') => {
  let tmpArray = []
  if (response.status === 200) {
    if (mode === 'generic' && response.data.items.length !== 0) {
      tmpArray = response.data.items
    }
  }
  return tmpArray
}

const getCurrentJWToken = () => {
  return localStorage.token
}

const getEmailFromToken = (token = getCurrentJWToken()) => {
  const tokenDecoded = jwt(token)
  if (tokenDecoded.sub) {
    return tokenDecoded.sub
  } else {
    return localStorage.user
  }
}

const deleteElementFromArray = (array, element) => {
  array.splice(array.indexOf(element.UID), 1)
  return array
}

const getMatchFromArray = (array, search) => {
  return array.find(function (element) {
    return element.getId === search
  })
}

const formatDate = (date, breakLine = false, separato = '-') => {
  if (date != null) {
    const dateTime = date.split('T')
    const yearMonthDay = dateTime[0].split('-')
    const hourMinutSeconds = dateTime[1].split(':')

    let day = yearMonthDay[2]
    const month = yearMonthDay[1]
    const year = yearMonthDay[0]
    let hour = hourMinutSeconds[0]
    const minutes = hourMinutSeconds[1]
    if (hour === 0) {
      hour = 24
      day = day - 1
    }
    if (breakLine) {
      return (
        <span>
          {day}/{month}/{year.substring(2, 4)}
          <br />
          {hour}:{minutes}h
        </span>
      )
    }
    return (
      <span>
        {day}/{month}/{year} &nbsp;&nbsp;&nbsp;&nbsp; {hour}:{minutes}
      </span>
    )
  } else {
    return <span> -</span>
  }
}

const getCurrentLanguage = () => {
  const i18nextLng = localStorage.getItem('i18nextLng') || 'ca'
  return ['ca', 'en', 'es'].includes(i18nextLng) ? i18nextLng : 'ca'
}

const cleanObjectIds = (id) => {
  return id.replace(process.env.REACT_APP_GUILLOTINA, '')
}

const copyGuillotinaObjectWithoutIds = (obj) => {
  const newObj = { ...obj }
  delete newObj['@id']
  delete newObj['@name']
  delete newObj['@uid']
  delete newObj['parent']
  delete newObj['uuid']

  return newObj
}

const copyObjecIds = (obj, ids) => {
  const newObj = {}
  ids.map((id) => {
    if (obj[id]) {
      newObj[id] = obj[id]
    }
  })

  return newObj
}

const getValueVocabularyDynamic = (items, value, lang) => {
  const result = (items && value && items.find((o) => o.token === value)) || null
  if (result) {
    if (`lang-${lang}` in result['title']) {
      return result['title'][`lang-${lang}`]
    }
    return result['title']['default']
  }
  return result
}

export {
  cleanObjectIds,
  copyGuillotinaObjectWithoutIds,
  copyObjecIds,
  deleteElementFromArray,
  formatDate,
  getCurrentJWToken,
  getCurrentLanguage,
  getEmailFromToken,
  getMatchFromArray,
  getValueVocabularyDynamic,
  returnGetData,
}
