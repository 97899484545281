import { Chip } from '@mui/material'
import { AddCalendarIcon } from 'NewVersion/icons/AddCalendarIcon'
import { AlertIcon } from 'NewVersion/icons/AlertIcon'
import { CalendarIcon } from 'NewVersion/icons/CalendarIcon'
import { TrashIcon } from 'NewVersion/icons/TrashIcon'
import { formatDateDayHours, formatDateOnlyDay, getDayBefore } from 'NewVersion/utils/date'
import { getMainImage } from 'NewVersion/utils/image'
import { formattedPerformancesBySameData } from 'NewVersion/utils/performance'
import * as path from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { useFavourites } from 'hooks/useFavourites'
import { useGetVocabularyDynamic } from 'hooks/useGetVocabularyDynamic'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AUDIT_ACTIONS } from 'utils/audit'
import { getCurrentLanguage } from 'utils/utils'

export function ShowGridItem({ item }) {
  const { favouiteIds, addFavourite, removeFavourite, isFavouritesLoading } = useFavourites()
  const { t } = useTranslation()
  const currentLanguage = getCurrentLanguage()
  const isPro = item.type_name === 'ActivitatProfessional'
  const isOFF = !isPro && item.seccio.includes('off')

  const { edition } = useEdition()

  const { vocabularyWithoutFormat: vocabularyAmbit } = useGetVocabularyDynamic(
    'ambits',
    currentLanguage,
    edition
  )

  const getTypeShow = () => {
    if (isPro) {
      return <Chip className="chipTypeShow professional" label="PRO" variant="outlined" />
    }
    return isOFF ? (
      <Chip className="chipTypeShow off" label="OFF" variant="outlined" />
    ) : (
      <Chip className="chipTypeShow ofical" label="OFICIAL" variant="outlined" />
    )
  }

  // amb_canvis
  const isShowChanges = useMemo(() => {
    if (item && (item.actuacions ?? []).length > 0) {
      return item.actuacions.some((actuacio) => actuacio.canvis && actuacio.canvis.length > 0)
    }
    return false
  }, [item])

  // totes les actuacions cancel·lades
  const isAllPerformancesCanceled = useMemo(() => {
    if (item && (item.actuacions ?? []).length > 0) {
      return !item.actuacions.some((actuacio) => !actuacio.cancelat)
    }
    return false
  }, [item])

  const isFavourite = useMemo(() => {
    if (isPro) {
      return favouiteIds.includes(item['@uid'])
    }
    if (item && (item.actuacions ?? []).length > 0) {
      return item.actuacions.some((actuacio) => favouiteIds.includes(actuacio['@uid']))
    }
    return false
  }, [item, favouiteIds, isPro])

  // agrupar actuacions per data indepentment d'espai = false
  const formattedPerformances = useMemo(() => {
    if (item && (item.actuacions ?? []).length > 0) {
      return formattedPerformancesBySameData(item.actuacions, false)
    }
    return []
  }, [item])

  // (pro/de pagament/gratuït)
  const performanceOptions = useMemo(() => {
    const opcions = []
    if (item && (item.actuacions ?? []).length > 0) {
      /* pro*/
      if (item.actuacions.some((actuacio) => actuacio.preu_access_professional)) {
        opcions.push(t('pro_access'))
      }
      /* de pagament*/
      const hiHaPreu = (actuacio) => actuacio.preu > 0
      const hiHaPreuInversa = (actuacio) => actuacio.preu_inversa > 0
      const hiHaPreuAnticipada = (actuacio) => actuacio.preu_anticipada > 0
      const preu = item.actuacions[0]?.preu
      const preu_anticipada = item.actuacions[0]?.preu_anticipada
      const preu_inversa = item.actuacions[0]?.preu_inversa
      if (item.actuacions.some(hiHaPreu || hiHaPreuInversa || hiHaPreuAnticipada)) {
        if (item.actuacions.some(hiHaPreu && hiHaPreuAnticipada) && preu !== preu_anticipada) {
          opcions.push((preu_anticipada ? preu_anticipada : '') + ' - ' + (preu ? preu : '') + ' €')
        } else {
          item.actuacions.some(hiHaPreu)
            ? opcions.push(preu + ' €')
            : item.actuacions.some(hiHaPreuAnticipada)
            ? opcions.push(preu_anticipada + ' €')
            : opcions.push(preu_inversa + ' €')
        }
      }
      /* gratuït*/
      if (item.actuacions.some((actuacio) => actuacio.preu === 0 || actuacio.entrada_amb_reserva)) {
        opcions.push(t('free_access'))
      }
    }
    return opcions
  }, [item, t])

  const getArtists = () => {
    if (isPro) {
      return getLinkShow(item.artista)
    }

    return getLinkShow(item.artistes_info.length > 0 && item.artistes_info.join(' + '))
  }

  const getLinkShow = (text) => {
    if (isPro) {
      return (
        <Link
          to={`${process.env.REACT_APP_PUBLIC_WEB}/professionals/activitats-professionals/${edition}/${item.parent_name}/${item.id}`}
          target="_blank"
          rel="noreferrer"
          className="reset-anchor"
          data-test="espectacleLink"
        >
          {text}
        </Link>
      )
    }

    return (
      <Link
        key={item['id']}
        to={path.SHOWS_PATH.concat('/'.concat(item.id))}
        className="reset-anchor"
        data-test="espectacleLink"
      >
        {text}
      </Link>
    )
  }

  const getNames = () => {
    if (isPro) {
      return getLinkShow(item[`nom_${currentLanguage}`] ? item[`nom_${currentLanguage}`] : '')
    }

    return getLinkShow(item.title ? item.title : '')
  }

  const getAmbit = () => {
    if (isPro) {
      return item[`parent_title_${currentLanguage}`]
    }
    return item.ambit && vocabularyAmbit ? vocabularyAmbit[item.ambit] : ''
  }

  const renderButton = () => {
    if (isFavourite) {
      return (
        <div
          className={'show-info'}
          onClick={() => {
            if (!isFavouritesLoading) {
              if (isPro) {
                removeFavourite(item['@uid'], AUDIT_ACTIONS.REMOVE_ACTIVITY_FAVOURITE)
              } else {
                const ids = item.actuacions.map((actuacio) => actuacio['@uid'])
                removeFavourite(ids, AUDIT_ACTIONS.REMOVE_PERFORMANCE_FAVOURITE, true)
              }
            }
          }}
        >
          <div className={'show-info__icon'}>
            <TrashIcon />
          </div>
          <div className={'show-info__label'}>{t('delete')}</div>
        </div>
      )
    }
    return (
      <div
        className={'show-info'}
        onClick={() => {
          if (!isFavouritesLoading) {
            if (isPro) {
              addFavourite(item['@uid'], AUDIT_ACTIONS.ADD_ACTIVITY_FAVOURITE)
            } else {
              const ids = item.actuacions.map((actuacio) => actuacio['@uid'])
              addFavourite(ids, AUDIT_ACTIONS.ADD_PERFORMANCE_FAVOURITE, true)
            }
          }
        }}
      >
        <div className={'show-info__icon'}>
          <AddCalendarIcon />
        </div>
        <div className={'show-info__label'}>{t('add')}</div>
      </div>
    )
  }

  return (
    <>
      <div className={'show-grid-item'}>
        {/* Imatge*/}
        <div
          className={'show-head'}
          style={{
            backgroundImage: `url(${
              isPro
                ? getMainImage({
                    item: item,
                    size: 'large',
                    edition,
                    type: `activitats-professionals/${
                      item['@id'].split('/')[item['@id'].split('/').length - 2]
                    }`,
                  })
                : getMainImage({ item: item, edition })
            })`,
          }}
        >
          {/* Cancel·lat*/}
          {isAllPerformancesCanceled && (
            <div className={'show-mask'}>
              <div>{t('canceled')}</div>
            </div>
          )}

          {/* Canvi hora*/}
          {isShowChanges && !isAllPerformancesCanceled && (
            <div className={'show-mask-time-change'}>
              <div className={'show-mask-time-change__icon'}>
                <AlertIcon />
              </div>
              <div className={'show-mask-time-change__label'}>{t('with_changes')}</div>
            </div>
          )}
        </div>

        <div className={'show-main'}>
          <div className={'show-main-items'}>
            {/*  Àmbit */}
            <div className={'show-ambits'} data-test="gridCellAmbit">
              {getAmbit()}
            </div>

            {/*  Artists */}
            <div className={'show-artists'}>{getArtists()}</div>

            {/*  Title */}
            <div className={'show-title'}>{getNames()}</div>

            {/*  Calendar */}
            <div className={'show-calendar'} data-test="gridCellDate">
              {(isPro || formattedPerformances.length > 0) && (
                <div className={'show-calendar__icon'}>
                  <CalendarIcon />
                </div>
              )}

              <div className={'show-calendar__text'}>
                {isPro
                  ? formatDateDayHours(item.data, currentLanguage)
                  : formattedPerformances.map((actuacio, index) => {
                      return (
                        <div key={`calendar_show_${index}`}>
                          <span
                            className={actuacio.cancelat ? 'show-calendar__text__canceled' : ''}
                          >
                            <b>
                              {actuacio.amagar_info_data
                                ? t('hide_performance_date')
                                : formatDateOnlyDay(actuacio.data, currentLanguage)}
                            </b>
                            {actuacio.amagar_info_data
                              ? ''
                              : `: ${actuacio.hores
                                  .map((item) => `${item.replace(':', '.')} h`)
                                  .join(' / ')}`}
                            {actuacio.night
                              ? ` (${getDayBefore(
                                  actuacio.data,
                                  currentLanguage
                                ).toLowerCase()} ${t('night')})`
                              : ''}
                          </span>
                          {actuacio.cancelat && (
                            <span className={'show-calendar__text__canceled__title'}>
                              {' '}
                              {t('canceled')}
                            </span>
                          )}
                        </div>
                      )
                    })}
              </div>
            </div>
          </div>
          <div data-test="gridCellTypeShow">{getTypeShow()}</div>
        </div>

        {/*  opcions preu */}
        <div className={'show-item-footer'}>
          {isPro ? (
            <div className={'show-performance-options'}>{t(item.acces)}</div>
          ) : (
            performanceOptions.length > 0 && (
              <div className={'show-performance-options'}>{performanceOptions.join(' / ')}</div>
            )
          )}
          {renderButton()}
        </div>
      </div>
    </>
  )
}
