import { Chip, TableCell, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import { AddCalendarIcon } from 'NewVersion/icons/AddCalendarIcon'
import { CalendarIcon } from 'NewVersion/icons/CalendarIcon'
import { EntradaIcon } from 'NewVersion/icons/EntradaIcon'
import { LocationIcon } from 'NewVersion/icons/LocationIcon'
import { PropostesIcon } from 'NewVersion/icons/PropostesIcon'
import { TrashIcon } from 'NewVersion/icons/TrashIcon'
import { UserIcon } from 'NewVersion/icons/UserIcon'
import * as path from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AUDIT_ACTIONS } from 'utils/audit'
import { formatDate, getCurrentLanguage, getValueVocabularyDynamic } from 'utils/utils'
import Chips from '../UI/Chips/Chips'

const ScheduleTableItemComponent = ({
  showOfSchedule,
  vocabularies,
  isFavourite,
  addFavourite,
  removeFavourite,
  isFavouritesLoading,
  type,
  format,
}) => {
  const currentLanguage = getCurrentLanguage()
  const { t } = useTranslation()
  const isPro = showOfSchedule.type_name === 'ActivitatProfessional'
  const isOFF = !isPro && showOfSchedule.seccions.includes('off')

  const { edition } = useEdition()

  const getArtistAndNames = () => {
    if (isPro) {
      return (
        <Link
          to={`${process.env.REACT_APP_PUBLIC_WEB}/professionals/activitats-professionals/${edition}/${showOfSchedule.parent_name}/${showOfSchedule.id}`}
          target="_blank"
          rel="noreferrer"
          className="reset-anchor"
          data-test="espectacleLink"
        >
          <b>{showOfSchedule.artista}</b>
          <br />
          {showOfSchedule[`nom_${currentLanguage}`]}
        </Link>
      )
    }

    return showOfSchedule.espectacles_data.map((show) => {
      return (
        <Link
          key={show['id']}
          to={path.SHOWS_PATH.concat('/'.concat(show.id))}
          className="reset-anchor"
          data-test="espectacleLink"
        >
          <div>
            <strong>{show.artistes_info.join(' + ')}</strong>
          </div>
          <div>{show.title}</div>
        </Link>
      )
    })
  }

  const getAmbit = () => {
    if (isPro) {
      return showOfSchedule[`parent_title_${currentLanguage}`]
    }
    return (showOfSchedule.ambits ?? [])
      .map((ambit) => getValueVocabularyDynamic(vocabularies.ambits, ambit, currentLanguage))
      .join(' ')
  }

  const getTypeShow = () => {
    if (type === 'agenda') return <></>
    if (isPro) {
      return <Chip className="chipTypeShow professional" label="PRO" variant="outlined" />
    }
    return isOFF ? (
      <Chip className="chipTypeShow off" label="OFF" variant="outlined" />
    ) : (
      <Chip className="chipTypeShow ofical" label="OFICIAL" variant="outlined" />
    )
  }

  const getTypeTiket = () => {
    if (isPro) {
      return t(showOfSchedule.acces)
    }
    return t(showOfSchedule.acces)
  }

  const renderScheduleButton = () => {
    if (isFavourite) {
      return (
        <Chips
          text={t('delete')}
          icon={
            <div style={{ width: '16px', height: '16px' }}>
              <TrashIcon />
            </div>
          }
          dataTest={'deleteCalendar'}
          tooltip={t('remove_to_shecule')}
          disabled={isFavouritesLoading}
          onClick={() => {
            removeFavourite(
              showOfSchedule['@uid'],
              isPro
                ? AUDIT_ACTIONS.REMOVE_ACTIVITY_FAVOURITE
                : AUDIT_ACTIONS.REMOVE_PERFORMANCE_FAVOURITE
            )
          }}
        />
      )
    }
    return (
      <Chips
        text={t('add')}
        icon={
          <div style={{ width: '16px', height: '16px' }}>
            <AddCalendarIcon />
          </div>
        }
        dataTest={'addCalendar'}
        tooltip={t('add_to_shecule')}
        disabled={isFavouritesLoading}
        onClick={() => {
          addFavourite(
            showOfSchedule['@uid'],
            isPro ? AUDIT_ACTIONS.ADD_ACTIVITY_FAVOURITE : AUDIT_ACTIONS.ADD_PERFORMANCE_FAVOURITE
          )
        }}
      />
    )
  }

  return format === 'table' ? (
    <TableRow
      key={`scheduleTableItem-${showOfSchedule.id}`}
      className={`table-row ${type !== 'agenda' && isPro && 'row-pro'}`}
    >
      <TableCell data-test="tableCellDate">{formatDate(showOfSchedule.data, true)}</TableCell>
      <TableCell data-test="tableCellAmbit" sx={{ maxWidth: '300px' }}>
        {getTypeShow()}
        <br />
        {getAmbit()}
      </TableCell>
      <TableCell data-test="tableCellArtistName">{getArtistAndNames()}</TableCell>
      <TableCell>
        {vocabularies &&
          getValueVocabularyDynamic(vocabularies.espai, showOfSchedule.espai, currentLanguage)}
      </TableCell>
      <TableCell>{getTypeTiket()}</TableCell>
      <TableCell>{renderScheduleButton()}</TableCell>
    </TableRow>
  ) : (
    <Box className={`table-row-mobile display-sm ${type !== 'agenda' && isPro && 'row-pro'}`}>
      <Box flex={1}>
        <Box className="line">
          <CalendarIcon />
          <p>{formatDate(showOfSchedule.data)}</p>
        </Box>
        <Box className="line">
          <PropostesIcon />
          <p>{getAmbit()}</p>
        </Box>
        <Box className="line">
          <UserIcon />
          <p>{getArtistAndNames()}</p>
        </Box>
        <Box className="line">
          <LocationIcon />
          <p>
            {vocabularies &&
              getValueVocabularyDynamic(vocabularies.espai, showOfSchedule.espai, currentLanguage)}
          </p>
        </Box>
        {type !== 'agenda' && getTypeTiket() && (
          <Box className="line">
            <EntradaIcon />
            <p>{getTypeTiket()}</p>
          </Box>
        )}
      </Box>
      {renderScheduleButton()}
      {getTypeShow()}
    </Box>
  )
}

export const ScheduleTableItem = React.memo(ScheduleTableItemComponent)
